import { useState, useEffect } from "react";
import { IArbAlchemyNFT } from "../type/arbipegs";
import { logger } from "../utils/arbLogger";
import { trpc } from "../utils/trpc";
import { useDispatch } from "react-redux";

export const useArbTokensOwnedFromCollection = (collectionAddress: string, walletAddress: string) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<IArbAlchemyNFT[]>([]);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [fromApis, setFromApis] = useState<boolean>(false);
    const [fromRedis, setFromRedis] = useState<boolean>(false);
    const [fromRedux, setFromRedux] = useState<boolean>(false);

    const { data: ownedFromCollection, isLoading: ownedFromCollectionLoading, isFetched: ownedFromCollectionFetched } = trpc.user.ownedNFTsForCollection.useQuery({ ownerAddress: walletAddress, collectionAddress: collectionAddress });

    logger.trace("HOOK ALCHEMY OWNED TOKENS FROM COLLECTION INFO", collectionAddress, walletAddress, ownedFromCollection, ownedFromCollectionLoading, ownedFromCollectionFetched);

    useEffect(() => {
        if (ownedFromCollectionFetched) {
            //@ts-ignore
            setData(ownedFromCollection?.nfts)
        }
    }, [walletAddress, ownedFromCollectionFetched, ownedFromCollection]);

    return { data: data, error: error, isLoading: loading, fromApis: fromApis, fromRedux: fromRedux, fromRedis: fromRedis };
}
