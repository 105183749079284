import { useState, useEffect } from "react";
import { IArbMarketplaceCollection } from "../type/arbipegs";
import { trpc } from "../utils/trpc";

export const useArbCollectionMarketplace = (collectionAddress: string) => {
    const [data, setData] = useState<IArbMarketplaceCollection>();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [fromApis, setFromApis] = useState<boolean>(false);
    const [fromRedis, setFromRedis] = useState<boolean>(false);
    const [fromRedux, setFromRedux] = useState<boolean>(false);
    const [directListings, setDirectListings] = useState<any>([]);
    const [directListingsFetched, setDirectListingsFetched] = useState<boolean>(false);
    const [englishAuctions, setEnglishAuctions] = useState<any>([]);
    const [englishAuctionsFetched, setEnglishAuctionsFetched] = useState<boolean>(false);
    const [offers, setOffers] = useState<any>([]);
    const [offersFetched, setOffersFetched] = useState<boolean>(false);

    const { data: fromCollection, isLoading: fromCollectionLoading, isFetched: fromCollectionFetched } = trpc.indexer.collection.useQuery({ address: collectionAddress });

    useEffect(() => { 
        if (fromCollectionFetched) {

            let arbMarketplace: IArbMarketplaceCollection = {
                address: collectionAddress,
                directListings: fromCollection?.listings ? fromCollection.listings : [],
                englishAuctions: fromCollection?.auctions ? fromCollection.auctions : [],
                offers: fromCollection?.offers ? fromCollection.offers : [] ,
                isListed: false,
                isAuctioned: false,
                isMarketplaced: false,
                hasOffers: false,
            }
            arbMarketplace.isListed = (arbMarketplace.directListings.length > 0) ? true : false
            arbMarketplace.isAuctioned = (arbMarketplace.englishAuctions.length > 0) ? true : false
            arbMarketplace.isMarketplaced = arbMarketplace.isAuctioned || arbMarketplace.isListed
            arbMarketplace.hasOffers = (arbMarketplace.offers.length > 0) ? true : false
            //logger.trace("TRACE IN EFFECT API DO")
            setData(arbMarketplace);
            setLoading(false);
            setFromApis(true);
            //Refresh Redux Cache
            //dispatch(cacheToken(arbToken));
            //Refresh Redis Cache
            //mutation.mutate({ "key": collectionAddress+":"+tokenId, "value": JSON.stringify(arbToken) });

        }
    }, [collectionAddress, fromCollection, fromCollectionFetched]);

    return { data: data, error: error, isLoading: loading, fromApis: fromApis, fromRedux: fromRedux, fromRedis: fromRedis };
}
