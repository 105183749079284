import React from "react";
import "tippy.js/dist/tippy.css";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { trpc } from "../../utils/trpc";
import { useAddress, useContract, useValidDirectListings, useValidEnglishAuctions } from "@thirdweb-dev/react";
import AssetThumb from "../arbipegs/list/AssetThumb";
import { logger } from "../../utils/arbLogger";
import AuctionAssetThumb from "../arbipegs/list/AuctionAssetThumb";
import { useArbTokensOwnedFromCollection } from "../../hooks/useArbTokensOwnedFromCollection";
import { useArbCollectionMarketplace } from "../../hooks/useArbCollectionMarketplace";
import { getTopOfferForToken } from "../../utils/transform/offer";
import { ethers } from "ethers";

const CategoryItem = () => {
  const dispatch = useDispatch();
  const router = useRouter()
  const collectionAddress = router.query.collection;
  const walletAddress = useAddress();

  //@ts-ignore
  const { data: collectionNFTs, fetchNextPage } = trpc.nft.infiniteCollectionNFTs.useInfiniteQuery({ address: collectionAddress, limit: 40 }, { getNextPageParam: (lastPage) => lastPage.nextCursor });
  /*   useEffect(() => {
      // Calling fetchNfts on component
  
      if(collectionAddress)
        getNftsForCollection(collectionAddress);
    }, [collectionAddress]); */
  logger.debug("COLLECTION NFTs", collectionNFTs)
  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  //@ts-ignore
  const { data: collectionMarketplaceData } = useArbCollectionMarketplace(collectionAddress);
  logger.trace("useArbCollectionMarketplace", collectionMarketplaceData)


  //@ts-ignore
  const { data: ownedFromCollection } = useArbTokensOwnedFromCollection(collectionAddress, walletAddress)
  logger.trace("useArbTokensOwnedFromCollection", ownedFromCollection)
  return (
    <div>
      <div className="grid-container grid grid-cols-2 gap-4 md:gap-5 zes:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xml:grid-cols-6">
        {ownedFromCollection?.map((nft) => {
          const {
            tokenUri,
            title,
            tokenType,
            balance,
            contract,
            media,
            tokenId,
            description
          } = nft;
          //@ts-ignore
          const foundDirectListings = collectionMarketplaceData?.directListings?.find(directListing => (parseInt(directListing.listing.token_id, 16) == tokenId));
          const foundEnglishAuctions = collectionMarketplaceData?.englishAuctions?.find(englishAuction => (parseInt(englishAuction.auction.token_id, 16) == parseInt(tokenId)));
          const found = foundDirectListings?.listing || foundEnglishAuctions?.auction
          //@ts-ignore
          const topOfferForToken = found? {} : getTopOfferForToken(collectionMarketplaceData?.offers, tokenId)
          logger.debug("OWNED??", tokenId, found, collectionMarketplaceData)
          return (
            <>
              {foundDirectListings && 
              <AssetThumb key={`owned-assetThumb-${contract.address}-${tokenId}`} id={tokenId} contractAddress={contract.address}
                  imageURL={media[0]?.thumbnail ? media[0].thumbnail : media[0].gateway}
                  name={title}
                  description={description}
                  type={tokenType} balance={found.quantity} contractName={contract.name}
                  endTime={found.end_timestamp}
                  listingId={found.listing_id}
                  listingType={"direct"}
                  unitPrice={ethers.utils.formatUnits(found.price)} isOwner={true}
                  cancelButton={true}
                  topOffer={found.max_offer?ethers.utils.formatUnits(BigInt(found.max_offer)):null}
                />
              }
              {foundEnglishAuctions && <AuctionAssetThumb id={tokenId} contractAddress={contract.address} imageURL={media[0]?.thumbnail ? media[0].thumbnail : media[0].gateway} name={title} description={description} type={tokenType} balance={balance} contractName={contract.name} listingId={found.id} unitPrice={found.currencyValuePerToken.displayValue} cancelButton={true} isOwner={true} topOffer={topOfferForToken} endTime={foundEnglishAuctions[0].endTimeInSeconds} />}
              {!found && <AssetThumb id={tokenId} contractAddress={contract.address} imageURL={media[0]?.thumbnail ? media[0].thumbnail : media[0].gateway} name={title} description={description} type={tokenType} balance={balance} contractName={contract.name} sellButton={true} isOwner={true} topOffer={topOfferForToken} />}
            </>
          );
        })

        }
        {collectionMarketplaceData?.directListings?.map((collectionDirectListing) => {
          logger.debug("collectionDirectListing", collectionDirectListing)
          const {
            quantity,
            price: currencyValuePerToken,
            end_timestamp: endTimeInSeconds,
            listing_id: id,
            alchemy: asset,
            contract_address: assetContractAddress,
            creator_address: creatorAddress,
            token_id: tokenId,
            max_offer: topOfferForToken
          } = collectionDirectListing.listing;
          logger.debug("Asset", asset)
          const found = ownedFromCollection?.find(owned => (parseInt(owned.tokenId) == parseInt(tokenId, 16)));
          logger.debug("Owned?", ownedFromCollection, tokenId, found);
          // const topOfferForToken = getTopOfferForToken(collectionMarketplaceData?.offers, tokenId)
          return (
            <>
              {found ? <></> :
                <AssetThumb key={`assetThumb-${assetContractAddress}-${tokenId}`} id={tokenId} contractAddress={assetContractAddress}
                  imageURL={asset?.media[0].thumbnail} name={asset?.metadata.name}
                  description={asset?.description} 
                  unitPrice={ethers.utils.formatUnits(currencyValuePerToken)}
                  balance={quantity}
                  endTime={endTimeInSeconds}
                  listingId={id}
                  listingType={"direct"}
                  buyButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? false : true}
                  cancelButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? true : false}
                  topOffer={topOfferForToken?ethers.utils.formatUnits(BigInt(topOfferForToken)):null}
                />}
            </>
          );
        })}
       {collectionMarketplaceData?.englishAuctions?.map((collectionEnglishAuction) => {
          const {
            auction_id: id,
            alchemy: asset,
            contract_address: assetContractAddress,
            creator_address: creatorAddress,
            token_id: tokenId,
            quantity,
            buyoutCurrencyValue,
            currencyContractAddress,
            startTimeInSeconds,
            end_timestamp: endTimeInSeconds,
            minimum_bid_amount: minimumBidCurrencyValue,
            max_offer: topOfferForToken
          } = collectionEnglishAuction.auction;
          const found = ownedFromCollection?.find(owned => (parseInt(owned.tokenId) == parseInt(tokenId, 16)));
          //const topOfferForToken = getTopOfferForToken(collectionMarketplaceData?.offers, tokenId)
          return (
            <>
            {found ? <></> : <AuctionAssetThumb key={`assetThumb-${assetContractAddress}-${tokenId}`} id={tokenId} contractAddress={assetContractAddress}
              imageURL={asset?.media[0].thumbnail} name={asset?.metadata.name}
              description={asset?.description}
              unitPrice={ethers.utils.formatUnits(minimumBidCurrencyValue)}
              listingId={id}
              endTime={endTimeInSeconds}
              cancelButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? true : false}
              bidButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? false : true}
              topOffer={topOfferForToken?ethers.utils.formatUnits(BigInt(topOfferForToken)):null}
            />
          }
          </>
          );
        })}
        {collectionNFTs?.pages.map((page, index) => {
          return (
            <>
              {
                page.nfts.map((nft: any, nftIndex) => {
                  const {
                    media,
                    thumbnail,
                    imageRaw,
                    title,
                    description,
                    contract,
                    tokenId,
                    tokenType,
                    symbol,
                    error
                  } = nft;
                  if (error) return (<></>)
                  const foundOwned = ownedFromCollection?.find(owned => (owned.tokenId == tokenId));
                  if (foundOwned) return (<></>)
                  const foundEnglishAuction = collectionMarketplaceData?.englishAuctions?.find(englishAuction => (parseInt(englishAuction.auction.token_id, 16) == parseInt(tokenId)));
                  if (foundEnglishAuction) return (<></>)
                  const foundDirectListings = collectionMarketplaceData?.directListings?.find(directListing => (parseInt(directListing.listing.token_id, 16) == parseInt(tokenId)));
                  if (foundDirectListings) return (<></>)
                  const offersForToken = collectionMarketplaceData?.offers?.filter(marketplaceOffer => (marketplaceOffer.tokenId == tokenId))
                  const topOfferForToken = (offersForToken && offersForToken.length > 1) ? [...offersForToken.entries()].reduce((topOffer, offerForToken) => (parseInt(offerForToken[1].totalPrice) > parseInt(topOffer.totalPrice)) ? offerForToken[1] : topOffer, { totalPrice: "0" }) : (offersForToken ? offersForToken[0] : null)
                  logger.trace("TOPOFFER", topOfferForToken)
                  return (
                    <AssetThumb key={`assetThumb-${contract}-${tokenId}`} id={tokenId} contractAddress={contract} imageURL={thumbnail} imageRaw={imageRaw} name={title} description={description} type={tokenType} makeOfferButton={true} topOffer={topOfferForToken} />
                  );
                })
              }</>);
        })}
      </div>
      {collectionNFTs?.pages[collectionNFTs?.pages.length - 1]?.nextCursor ?
        <div className="flex flex-row justify-between">
          <button
            className="mt-12 mx-auto bg-accent hover:bg-accent-dark hover:scale-105 rounded-xl py-4 px-8 text-center w-[220px] font-display text-sm text-white transition-all"
            onClick={() => handleFetchNextPage()}
          >
            <span>Load more...</span>
          </button>
        </div>
        : <></>
      }
    </div>

  );
};

export default CategoryItem;
