export function getTopOfferForToken(offers: any[], tokenId: string) {
    const offersForToken = offers?.filter(offer => (offer.tokenId == tokenId))
    const topOfferForToken = (offersForToken && offersForToken.length > 1) ? [...offersForToken.entries()].reduce((topOffer, offerForToken) => (parseInt(offerForToken[1].totalPrice) > parseInt(topOffer.totalPrice)) ? offerForToken[1] : topOffer, {totalPrice: "0"}) : (offersForToken ? offersForToken[0] : null)
    return topOfferForToken
}

export function getTopOfferForTokenFromCollection(offers: any[], collectionAddress: string, tokenId: string) {
    const offersForTokenFromCollection = offers?.filter(offer => (offer.tokenId == tokenId && offer.assetContractAddress.toLowerCase() == collectionAddress.toLowerCase()))
    const topOfferForTokenFromCollection = (offersForTokenFromCollection && offersForTokenFromCollection.length > 1) ? [...offersForTokenFromCollection.entries()].reduce((topOffer, offerForToken) => (parseInt(offerForToken[1].totalPrice) > parseInt(topOffer.totalPrice)) ? offerForToken[1] : topOffer, {totalPrice: "0"}) : (offersForTokenFromCollection ? offersForTokenFromCollection[0] : null)
    return topOfferForTokenFromCollection
}